import React, { useState } from "react";
import {
  Button,
  InputLabel,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Box } from "@mui/system";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "../Contexts/SnackbarProvider";
import { useDispatch, useSelector } from "react-redux";
import { userDetails } from "../Redux/Slice/chatSlice";

const SignIn = () => {
  const { showSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const notifyToken = useSelector((state) => state.notify.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const onSubmit = async (data) => {
    const updatedData = { ...data, deviceToken: notifyToken };
    console.log("notify", notifyToken);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}auth/login`,
        updatedData
      );
      console.log(response);

      if (response.data?.token) {
        let token = response.data?.token;

        localStorage.setItem("usertoken", token);
        dispatch(userDetails(response.data));
        localStorage.setItem("userId", response.data._id);
        localStorage.setItem("userName", response.data.username);
        showSnackbar("Successfully Logined !", "success");
        navigate("/dashboard");
      }
      console.log(response.data);
    } catch (error) {
      console.error("Login failed", error);
      showSnackbar(error?.response?.data?.message, "error");
      return;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        background: "#f4f4f4",
      }}
    >
      <Box
        sx={{
          border: "0px solid #dedede",
          p: 3,
          px: 4,
          py: 4,
          borderRadius: "10px",
          background: "white",
          width: { xs: "90%", sm: "60%", lg: "40%" },
          boxShadow: "0px 0px 25px 5px #f5f5f5",
        }}
      >
        <Typography sx={{ fontSize: "1.3rem", ml: 1, fontWeight: 700 }}>
          Sign In
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <InputLabel sx={{ mt: 4, ml: 1 }}>Email*</InputLabel>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{ required: true, pattern: { value: /^\S+@\S+$/i } }}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ py: 1 }}
                fullWidth
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ""}
              />
            )}
          />
          {errors.email ? (
            <Typography sx={{ mt: 0, ml: 1.1, color: "#f6685e" }}>
              Email is required
            </Typography>
          ) : null}

          <InputLabel sx={{ mt: 5, ml: 1 }}>Password*</InputLabel>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                type={showPassword ? "text" : "password"}
                sx={{ pb: 1 }}
                fullWidth
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ mb: 1 }}>
                      <IconButton onClick={togglePasswordVisibility}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          {errors.password ? (
            <Typography sx={{ mt: 0, ml: 1.1, color: "#f6685e" }}>
              Password is required
            </Typography>
          ) : null}

          <Box
            sx={{
              mt: 3,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{ width: "100%", fontWeight: 700, fontSize: "1.1rem" }}
            >
              Log in
            </Button>

            <Box sx={{ mt: 2, ml: 0.5 }}>
              <Typography sx={{ fontSize: "15px" }}>
                Not a user <Link to="/signup">Register</Link>
              </Typography>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default SignIn;
