import { Outlet, Navigate } from "react-router-dom";
import moment from "moment";
import "moment-timezone";
import { alpha } from "@mui/material";

export const PrivateRoutes = () => {
  let token = localStorage.getItem("usertoken");

  return token != null ? <Outlet /> : <Navigate to="/" />;
};
export const PrivateRoute = () => {
  let token = localStorage.getItem("usertoken");

  return token ? <Navigate to="/dashboard" /> : <Outlet />;
};
export const colors = [
  "#009688", // Teal
  "#FF5722", // Warm Orange
  "#673AB7", // Deep Purple
  "#4DB6AC", // Soft Aquatic
  "#F06292", // Pastel Pink
  "#8BC34A", // Light Sage
  "#03A9F4", // Sky Blue
  "#FF9800", // Vibrant Tangerine
];
export const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};
export const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
};
export const getColorIndex = (str) => {
  if (str) {
    let hash = 0;
    console.log("str", str);

    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return colors[Math.abs(hash) % colors.length];
  }
  return colors[0];
};

export const getOnlineStatus = (onlineUsers, currentUser) => {
  console.log("online", onlineUsers, "userId", currentUser);
  if (onlineUsers && currentUser) {
    const OnlineStatus = onlineUsers.find((user) => user == currentUser);
    if (OnlineStatus) {
      return true;
    }
    return false;
  }
  return false;
};
export const formattedDate = (utcTime) => {
  const utcMoment = moment.utc(utcTime);

  // Convert UTC time to IST
  const istMoment = utcMoment.tz("Asia/Kolkata");

  // Get the current time in IST
  const now = moment().tz("Asia/Kolkata");

  // Check if the time is within the last 24 hours
  const isWithin24Hours = now.diff(istMoment, "hours") < 24;

  // Format the output based on whether it is within the last 24 hours or not
  const formattedTime = isWithin24Hours
    ? istMoment.from(now)
    : istMoment.format("DD-MMM h:mm A");
  return formattedTime;
};

export function truncateString(str, maxLength = 7) {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + "...";
  }
  return str;
}

export const DarkText = {
  primary: "#fff",
  secondary: GREY[500],
  disabled: GREY[600],
};
