import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { addtoken } from "./Redux/Slice/notifySlice";

const firebaseConfig = {
  apiKey: "AIzaSyA7ruBNMDCsjQIaUYEKcwvC_Jj4Zk75i7U",

  authDomain: "chat-25d74.firebaseapp.com",

  projectId: "chat-25d74",

  storageBucket: "chat-25d74.appspot.com",

  messagingSenderId: "501404461429",

  appId: "1:501404461429:web:45db7ee8fbb60e72eb4b3c",
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async (dispatch) => {
  const permission = await Notification.requestPermission();
  console.log(permission);
  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey:
        "BJxdjaroJ0upJZPOUf2No78nOIfTDHknP5t8Yeths5exlb5wwnzQDXe5jeh6zJ4rHL16QWASXrVpsxv3asISwdM",
    });
    console.log("userToken", token);
    dispatch(addtoken(token));
  }
};
