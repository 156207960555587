import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token:"",
};

export const NotifySlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    addtoken: (state, action) => {
      state.token = action.payload;
    },
    removetoken: (state, action) => {
      state.token = "";
    },
  },
});

export const { addtoken, removetoken } = NotifySlice.actions;

export default NotifySlice.reducer;
