import React from "react";
import { Typography, Container, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h1"
        style={{ fontSize: "6rem", marginBottom: "1rem" }}
      >
        404
      </Typography>
      <Typography variant="h6" style={{ marginBottom: "2rem" }}>
        Oops! The page you are looking for does not exist.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/dashboard")}
        style={{ padding: "0.5rem 2rem" }}
      >
        Go to Home
      </Button>
    </Container>
  );
};

export default NotFound;
