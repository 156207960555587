import React, { lazy, Suspense, useEffect, useState } from "react";
import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import SignIn from "./LandingPages/SignIn";
import SignUp from "./LandingPages/SignUp";

import { PrivateRoute, PrivateRoutes } from "./utils/common";
import NotFound from "./Pages/NotFound";
import { SnackbarProvider } from "./Contexts/SnackbarProvider";

import { useDispatch, useSelector } from "react-redux";
import { generateToken, messaging } from "./firebase";
import { onMessage } from "firebase/messaging";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box, CircularProgress, Typography } from "@mui/material";

export const LoadingScreen = ({ props }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",

        ...props,
      }}
    >
      <CircularProgress />
    </Box>
  );
};
const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};
const DashBoard = Loadable(lazy(() => import("./Pages/Dashboard/DashBoard")));
function App() {
  const responseState = useSelector((state) => state.counter.response);
  const navigate = useNavigate();
  useEffect(() => {}, [responseState]);
  const token = localStorage.getItem("usertoken");
  useEffect(() => {
    if (!token) {
      navigate("/");
      return;
    } else {
      navigate("/dashboard");
    }
  }, [token]);
  const dispatch = useDispatch();
  useEffect(() => {
    generateToken(dispatch);
    onMessage(messaging, (payload) => {
      console.log(payload, "payload from app js");

      toast.success(
        <>
          <Typography sx={{ color: "white" }}>
            {payload.notification.title}
          </Typography>
          <Typography sx={{ color: "#7f7f7f" }}>
            {payload.notification.body}
          </Typography>
        </>,
        {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            backgroundColor: "#333",
            color: "#fff",
            borderRadius: "8px",
            padding: "10px",
            fontSize: "16px",
          },
        }
      );
    });
  }, [dispatch]);
  return (
    <>
      <ToastContainer />
      <SnackbarProvider>
        <Routes>
          <Route>
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
            </Route>
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route path="/dashboard" element={<DashBoard />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </SnackbarProvider>
    </>
  );
}

export default App;
