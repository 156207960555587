import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  OnetoOneData: "",
  userDetails: null,
  chatRoomId: null,
  response: false,
  currentOnlineUsers: null,
  chatType: "one-to-one",
  themeMode: "light",
  searchField: "",
};

export const chatSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    addChatOnetoOne: (state, action) => {
      console.log("ed", action.payload);
      state.OnetoOneData = action.payload;
    },
    userDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    chatRoomId: (state, action) => {
      state.chatRoomId = action.payload;
    },
    renderPage: (state, action) => {
      state.response = action.payload;
    },
    getCurrentOnlineUsers: (state, action) => {
      state.currentOnlineUsers = action.payload;
    },
    changeThemeMode: (state) => {
      state.themeMode = state.themeMode === "dark" ? "light" : "dark";
    },
    changeChatType: (state, action) => {
      state.chatType = action.payload;
    },
    searchField: (state, action) => {
      state.searchField = action.payload;
    },
  },
});

export const {
  increment,
  userDetails,
  incrementByAmount,
  addChatOnetoOne,
  chatRoomId,
  renderPage,
  getCurrentOnlineUsers,
  changeChatType,
  changeThemeMode,
  searchField
} = chatSlice.actions;

export default chatSlice.reducer;
