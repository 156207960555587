import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./Slice/chatSlice";
import notifyReducer from "./Slice/notifySlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    notify: notifyReducer,
  },
});
