import axios from "axios";


const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
  (request) => {
    const token =
      localStorage.getItem("usertoken") != null
        ? localStorage.getItem("usertoken")
        : null;
    request.headers.Authorization = `Bearer ${token}`;
    return request;
  },

  (err) => {
    Promise.reject(err);
  }
);

export default axiosInstance;
