import React, { useState } from "react";
import {
  Button,
  InputLabel,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Box } from "@mui/system";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../AxiosInstance";
import { useSnackbar } from "../Contexts/SnackbarProvider";
import { useDispatch, useSelector } from "react-redux";
import { userDetails } from "../Redux/Slice/chatSlice";
import { removetoken } from "../Redux/Slice/notifySlice";

const SignUp = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const { showSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const notifyToken = useSelector((state) => state.notify.token);
  const dispatch = useDispatch();
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const togglePasswordVisibilityConfirm = () => {
    setShowPasswordConfirm((prev) => !prev);
  };

  const onSubmit = async (data) => {
    if (data.password !== data.confirm_password) {
      return setError(true);
    }
    try {
      setError(false);
      console.log(data);
      const formData = {
        username: data.userName,
        email: data.email,
        password: data.password,
        deviceToken: notifyToken,
      };
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}auth/register`,
        formData
      );
      console.log(response);

      if (response.data?.token) {
        let token = response.data?.token;
        localStorage.setItem("usertoken", token);
        dispatch(userDetails(response.data));
        localStorage.setItem("userId", response.data._id);
        localStorage.setItem("userName", response.data.username);
        dispatch(removetoken());
        showSnackbar("successfully Registered !", "success");
        navigate("/dashboard");
      }
    } catch (error) {
      console.log("e", error);
      showSnackbar(error?.response?.data?.message, "error");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        background: "#f4f4f4",
      }}
    >
      <Box
        sx={{
          border: "0px solid #dedede",
          p: 3,
          px: 4,
          borderRadius: "10px",
          background: "white",
          width: { xs: "90%", sm: "60%", lg: "40%" },
          boxShadow: "0px 0px 25px 5px #f5f5f5",
        }}
      >
        <Typography sx={{ fontSize: "1.3rem", ml: 1, fontWeight: 700 }}>
          Sign Up
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <InputLabel sx={{ mt: 2, ml: 1 }}>User Name*</InputLabel>
          <Controller
            name="userName"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ py: 1 }}
                fullWidth
                error={!!errors.userName}
                helperText={errors.userName ? errors.userName.message : ""}
              />
            )}
          />
          {errors.userName ? (
            <Typography sx={{ mt: 0, ml: 1.1, color: "#f6685e" }}>
              User Name is required
            </Typography>
          ) : null}
          <InputLabel sx={{ mt: 2, ml: 1 }}>Email*</InputLabel>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{ required: true, pattern: { value: /^\S+@\S+$/i } }}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ py: 1 }}
                fullWidth
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ""}
              />
            )}
          />
          {errors.email ? (
            <Typography sx={{ mt: 0, ml: 1.1, color: "#f6685e" }}>
              Email is required
            </Typography>
          ) : null}

          <InputLabel sx={{ mt: 2, ml: 1 }}>Password*</InputLabel>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                type={showPassword ? "text" : "password"}
                sx={{ pb: 1 }}
                fullWidth
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ mb: 1 }}>
                      <IconButton onClick={togglePasswordVisibility}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          {errors.password ? (
            <Typography sx={{ mt: 0, ml: 1.1, color: "#f6685e" }}>
              Password is required
            </Typography>
          ) : null}

          <InputLabel sx={{ mt: 2, ml: 1 }}>Confirm Password*</InputLabel>
          <Controller
            name="confirm_password"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                type={showPasswordConfirm ? "text" : "password"}
                sx={{ pb: 1 }}
                fullWidth
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ mb: 1 }}>
                      <IconButton onClick={togglePasswordVisibilityConfirm}>
                        {showPasswordConfirm ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          {!error && errors.confirm_password ? (
            <Typography sx={{ mt: 0, ml: 1.1, color: "#f6685e" }}>
              Confirm Password is required
            </Typography>
          ) : null}
          {error ? (
            <Typography sx={{ mt: 0, ml: 1.1, color: "#f6685e" }}>
              Password & Confirm Password are not same
            </Typography>
          ) : null}

          <Box
            sx={{
              mt: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{ width: "100%", fontWeight: 700, fontSize: "1.1rem" }}
            >
              Register
            </Button>
            <Box sx={{ mt: 2, ml: 0.5 }}>
              <Typography sx={{ fontSize: "15px" }}>
                Already a user <Link to="/">Login</Link>
              </Typography>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default SignUp;
